import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import Img from 'gatsby-image'
import './modularHome.css'
import Tour from '../components/tour'
import { HomeByIdQuery } from '../generated-types'
import { get } from 'lodash-es'
import SEO from '../components/seo'
import { optionMeta, calculateCost, calculateOptionCost, Variable } from '../calculations'
import variables from '../variables.json'

// TODO: site cost
// TODO: luxury cost
// TODO: Solar HVAC package discount (Solar heating, and Solar cooling)
// TODO: Off-grid package discount (Off Grid PV Package, Solar heating, and Solar cooling)

function HomeOption(props: {
  home: HomeByIdQuery['modularHomesJson']
  optionKey: keyof typeof variables
  optionsState: any[]
  setOptionsState: any
  variables: Variable
  luxurySelected: boolean
}) {
  let { home, optionKey, optionsState, setOptionsState, variables, luxurySelected } = props
  let o = optionsState[optionKey]
  let meta = optionMeta[optionKey]
  if (home[optionKey] === '') {
    return null
  }
  let costDiv = (
    <span className="text-gray-600 font-medium">
      {' '}
      ${calculateOptionCost(1, variables, home, luxurySelected).toLocaleString()}
    </span>
  )
  if (optionKey === 'luxuryPackage') {
    costDiv = null
  }
  return (
    <label className="block checkbox" key={o.name}>
      <input
        type="checkbox"
        checked={o.value}
        onChange={(e: any) => {
          if (meta.group) {
            let groupOptions = Object.values(optionsState).filter(
              o => optionMeta[o.key].group === meta.group
            )
            for (let groupOption of groupOptions) {
              optionsState[groupOption.key].value = false
            }
          }
          o.value = e.target.checked
          setOptionsState({ ...optionsState })
        }}
      ></input>
      {' ' + o.name}
      {costDiv}
    </label>
  )
}

function ModularHomeSummary(props: {
  home: HomeByIdQuery['modularHomesJson']
  allModularHomesJson: HomeByIdQuery['allModularHomesJson']
  styleText: HomeByIdQuery['styleText']
  homeText: HomeByIdQuery['homeText']
}) {
  let { home, allModularHomesJson, styleText, homeText } = props
  const bedroomsAndStudys = Number(home.bedrooms) + Number(home.study != '')
  const firstFloorplan = home.floorplans[0]

  const [optionsState, setOptionsState] = React.useState(
    Object.entries(optionMeta)
      .filter(([key]) => variables[key])
      .map(([key, option]) => {
        let variable = variables[key]
        return {
          key: key,
          name: variable.name,
          type: 'checkbox',
          value: option.default !== undefined ? option.default : false
        }
      })
      .reduce(
        (acc, curr) => {
          acc[curr.key] = curr
          return acc
        },
        {} as {
          [k in keyof typeof variables]: {
            key: string
            name: string
            type: string
            value: boolean
          }
        }
      )
  )

  let formLayout: { name: string; options: (keyof typeof variables)[] }[] = [
    {
      name: 'Style',
      options: ['modern', 'funky', 'parapet', 'metro']
    },
    {
      name: 'Heating/Cooling',
      options: ['solarHeating', 'solarCooling', 'offGridPvPackage']
    },
    {
      name: 'Extras',
      // TODO: wait for variables in sheet
      // 'courtyard', 'study', 'extraLiving'
      options: [
        'extraDeck',
        'extraBalcony',
        'freeStandingGarage',
        'freeStandingCarport',
        'courtyard',
        'study',
        'extraLiving'
      ]
    }
  ]

  const luxurySelected = optionsState.luxuryPackage.value

  function getOptionsCost() {
    let _optionsCosts = []

    for (let key of Object.entries(optionMeta)
      .sort(([_k1, v1], [_k2, v2]) => v1.order - v2.order)
      .map(([k, _v]) => k)) {
      const v = variables[key]
      const option = optionsState[key]
      if (option) {
        if (key === 'luxuryPackage') {
          _optionsCosts.push({
            name: v.name,
            cost: calculateOptionCost(option.value, v, home, luxurySelected)
          })
        } else {
          _optionsCosts.push({
            name: v.name,
            cost: calculateOptionCost(Number(option.value), v, home, luxurySelected)
          })
        }
      } else {
        // console.warn(key, 'not found')
      }
    }
    let _discounts = []
    if (optionsState.solarCooling.value && optionsState.solarHeating.value) {
      let v = variables.solarHvacPackageDiscount
      let discount = {
        name: variables.solarHvacPackageDiscount.name,
        discount: -calculateOptionCost(1, v, home, luxurySelected)
      }

      if (optionsState.offGridPvPackage.value) {
        let v = variables.offGridPackageDiscount
        discount.name = variables.offGridPackageDiscount.name
        discount.discount -= calculateOptionCost(1, v, home, luxurySelected)
      }
      _discounts.push(discount)
    }
    return {
      discounts: _discounts,
      optionsCosts: _optionsCosts
    }
  }

  let { baseCost, siteCost } = calculateCost(home, luxurySelected)
  let { discounts, optionsCosts } = getOptionsCost()

  let internalTourAvailable = get(home, 'floorplans[0].internals[0]')
  let exteriors = (home.exteriors || []).filter(e => e.src != null)
  let externalTourAvailable = exteriors && exteriors.some(e => e.side != null)

  let optionsTotal = optionsCosts.map(o => o.cost).reduce((a, b) => a + b, 0)
  let discountTotal = discounts.map(d => d.discount).reduce((a, b) => a + b, 0)

  return (
    <div>
      <div className="container mx-auto px-6" style={{ paddingTop: 15 }}>
        <h1 className="mt-0 leading-tight text-xl">{home.name}</h1>
        <div className="mb-2 btn-group">
          {allModularHomesJson.nodes.map(other => {
            let selectedClass = other.id === home.id ? 'selected' : ''
            return (
              <Link
                key={other.id}
                className={'outline-button ' + selectedClass}
                to={'/modular-homes/' + other.slug}
              >
                {other.description}
              </Link>
            )
          })}
        </div>
        <ul className="flex">
          <li><strong className="font-medium">{bedroomsAndStudys}</strong> Bedrooms</li>
          <div className="mx-2 font-black">&middot;</div>
          <li><strong className="font-medium">{home.twoLevels === '0' ? '1' : '2'}</strong> Storey</li>
          <div className="mx-2 font-black">&middot;</div>
          <li><strong className="font-medium">{home.totalArea}</strong> m²</li>
          {/* TODO: {home.bathroom} Bath */}
        </ul>
        {styleText && (
          <div className="markdown" dangerouslySetInnerHTML={{ __html: styleText.html }}></div>
        )}
        {homeText && (
          <div className="markdown" dangerouslySetInnerHTML={{ __html: homeText.html }}></div>
        )}
        <div>
          <Img fluid={firstFloorplan.src.childImageSharp.fluid} />
        </div>
      </div>
      <div className="bg-gray-100 pb-4">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap">
            <div className="w-full sm:w-6/12 flex flex-wrap">
              <div className="w-full">
                {formLayout.map((group, i) => {
                  let show = group.options.some(o => home[o] !== '')
                  if (!show) {
                    // home doesn't have this option. spreadsheet cell is empty.
                    return null
                  }
                  return (
                    <div key={i}>
                      <h2 className="text-primary mb-1">{group.name}</h2>
                      {group.options.map(option => (
                        <HomeOption
                          key={option}
                          home={home}
                          variables={variables[option]}
                          optionKey={option}
                          optionsState={optionsState}
                          setOptionsState={setOptionsState}
                          luxurySelected={luxurySelected}
                        ></HomeOption>
                      ))}
                    </div>
                  )
                })}

                {/* <HomeOption
                  home={home}
                  variables={variablesJson.luxuryPackage}
                  optionKey="luxuryPackage"
                  optionsState={optionsState}
                  setOptionsState={setOptionsState}
                  luxurySelected={luxurySelected}
                ></HomeOption> */}
              </div>
            </div>
            <div className="w-full sm:w-6/12">
              <h2 className="text-primary">Estimated cost</h2>
              <table className="w-full">
                <tbody>
                  <tr>
                    <th className="text-right w-1/2">Base cost</th>
                    <td className="w-1/2">${baseCost.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th className="text-right w-1/2">Options cost</th>
                    <td className="w-1/2">${optionsTotal.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th className="text-right w-1/2">Site cost</th>
                    <td className="w-1/2">${siteCost.toLocaleString()}</td>
                  </tr>
                  {/* {optionsCosts
                  .filter(o => o.cost != 0)
                  .map(o => (
                    <tr>
                      <th className="text-right">{o.name}</th>
                      <td>${o.cost.toLocaleString()}</td>
                    </tr>
                  ))} */}
                  {discounts.map(o => (
                    <tr key={o.name}>
                      <th className="text-right">{o.name}</th>
                      <td>&minus;${o.discount.toLocaleString()}</td>
                    </tr>
                  ))}
                  <tr>
                    <th className="text-right">Total</th>
                    <td>${(baseCost + optionsTotal - discountTotal).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {(externalTourAvailable || internalTourAvailable) && (
            <div>
              <h1>Tour</h1>
              <Tour
                external={externalTourAvailable}
                internal={internalTourAvailable}
                modularHomesJson={home}
              ></Tour>
            </div>
          )}
          {!externalTourAvailable && exteriors.length > 0 && (
            <Img fluid={exteriors[0].src.childImageSharp.fluid}></Img>
          )}
        </div>
      </div>
    </div>
  )
}

class ModularHomeTemplate extends React.Component<{
  data: HomeByIdQuery
  pageContext: any
  uri: string
}> {
  render() {
    const { styleText, homeText, modularHomesJson: home } = this.props.data

    if (this.props.pageContext.tourOnly) {
      return (
        <Tour
          internal={true}
          external={true}
          floorplan={home.floorplans[0]}
          modularHomesJson={home}
        ></Tour>
      )
    }

    return (
      <div>
        <SEO title={`${home.name} ${home.description}`} />
        <Layout>
          <ModularHomeSummary
            home={home}
            allModularHomesJson={this.props.data.allModularHomesJson}
            styleText={styleText}
            homeText={homeText}
          ></ModularHomeSummary>
        </Layout>
      </div>
    )
  }
}

export default ModularHomeTemplate

export const pageQuery = graphql`
  query HomeById($slug: String!, $name: String!) {
    modularHomesJson(slug: { eq: $slug }) {
      ...HomeFields
      ...HomeFieldsImages
    }
    styleText: markdownRemark(frontmatter: { name: { eq: $name } }) {
      id
      html
    }
    homeText: markdownRemark(frontmatter: { slugs: { eq: $slug } }) {
      id
      html
    }
    allModularHomesJson(
      filter: { name: { eq: $name }, floorplans: { elemMatch: { src: { id: { ne: null } } } } }
    ) {
      nodes {
        ...HomeFields
      }
    }
  }
`
