/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import { SiteQueryQuery } from '../generated-types'

const Layout = ({ children, dark }: { dark?: boolean; children }) => {
  const data: SiteQueryQuery = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            links {
              name
              link
            }
          }
        }
      }
    }
  `)

  // min-h-screen: so the footer is pushed to the bottom on short pages
  return (
    <div className={`min-h-screen flex flex-col ${dark ? 'dark' : ''}`}>
      <Header site={data.site} title={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer site={data.site}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
